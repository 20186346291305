import React from 'react'

function SecuritySVG({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="40"
      // height="46"
      className={className}
      fill="none"
      viewBox="0 0 40 46"
    >
      <path
        fill="#252525"
        stroke="#fff"
        strokeWidth="0.7"
        d="M38.042 6.561l-17.643-5.5a1.33 1.33 0 00-.799 0l-17.643 5.5C1.39 6.74 1 7.271 1 7.875V28.5c0 11.956 17.931 16.286 18.695 16.465a1.346 1.346 0 00.61 0C21.07 44.786 39 40.456 39 28.5V7.875c0-.603-.389-1.136-.958-1.314zm-1.756 21.94c0 9.03-14.024 13.108-16.287 13.706-2.265-.596-16.285-4.66-16.285-13.707V8.891L20 3.814l16.286 5.077v19.61z"
      ></path>
      <path
        fill="#252525"
        stroke="#fff"
        d="M19.601 6.815L7.387 10.623c-.57.177-.958.71-.958 1.313V28.5c0 6.669 11.837 10.36 13.187 10.758a1.327 1.327 0 00.76 0c1.35-.399 13.196-4.088 13.196-10.758V11.936c0-.603-.39-1.136-.959-1.313L20.4 6.815a1.328 1.328 0 00-.798 0zm11.256 6.138V28.5c0 3.046-5.525 6.353-10.86 8.004-5.332-1.652-10.854-4.959-10.854-8.004V12.953L20 9.568l10.857 3.385z"
      ></path>
      <path
        fill="#252525"
        stroke="#fff"
        strokeWidth="0.5"
        d="M15.929 21.625a4.125 4.125 0 002.714 3.884v1.616c0 .759.608 1.375 1.357 1.375.75 0 1.357-.616 1.357-1.375v-1.616a4.125 4.125 0 002.715-3.884c0-2.275-1.826-4.125-4.072-4.125-2.245 0-4.071 1.85-4.071 4.125zm5.428 0C21.357 22.383 20.75 23 20 23a1.368 1.368 0 01-1.357-1.375c0-.758.609-1.375 1.357-1.375.749 0 1.357.617 1.357 1.375z"
      ></path>
      <path
        fill="#DE3069"
        stroke="#fff"
        strokeWidth="0.7"
        d="M19.601 6.815L7.387 10.623c-.57.177-.958.71-.958 1.313V28.5c0 6.669 11.837 10.36 13.187 10.758a1.327 1.327 0 00.76 0c1.35-.399 13.196-4.088 13.196-10.758V11.936c0-.603-.39-1.136-.959-1.313L20.4 6.815a1.328 1.328 0 00-.798 0zm11.256 6.138V28.5c0 3.046-5.525 6.353-10.86 8.004-5.332-1.652-10.854-4.959-10.854-8.004V12.953L20 9.568l10.857 3.385z"
      ></path>
    </svg>
  )
}

export default SecuritySVG
