import React from 'react'

function ContactSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="597"
      // height="627"
      fill="none"
      viewBox="0 0 597 627"
      {...props}
    >
      <path
        fill="#F9EAE9"
        d="M78.513 207L0 217.706l6.79 49.791 78.512-10.706L78.513 207z"
      ></path>
      <path
        fill="#fff"
        d="M78.531 207.009c-2.76 3.463-5.593 6.807-8.377 10.234a1715.67 1715.67 0 01-8.497 10.055 1545.41 1545.41 0 01-8.948 10.353 3.57 3.57 0 00-.262.333c-2.654 3.023-5.308 6.058-7.973 9.08l-.393.429-.488-.31c-3.38-2.201-6.735-4.415-10.103-6.628-.119-.072-.238-.143-.357-.226a953.128 953.128 0 01-11.412-7.592c-3.618-2.428-7.247-4.915-10.865-7.39-3.606-2.547-7.223-5.01-10.817-7.616 3.725 2.142 7.414 4.415 11.115 6.628 3.7 2.261 7.39 4.522 11.067 6.831 4.093 2.546 8.199 5.14 12.292 7.77.012 0 .012 0 .024.012 3.07 1.952 6.129 3.915 9.199 5.891 2.416-2.714 4.843-5.451 7.27-8.14.013-.036.013-.036.025-.036 3.236-3.617 6.485-7.211 9.758-10.781a966.684 966.684 0 018.83-9.52c2.95-3.142 5.89-6.319 8.912-9.377z"
      ></path>
      <path
        fill="#fff"
        d="M34.751 240.055c-.035.023-.047.071-.083.095-.369.404-.726.821-1.095 1.214a141.218 141.218 0 01-5.379 5.616c-2.237 2.25-4.545 4.427-6.866 6.593-2.344 2.13-4.688 4.26-7.116 6.319-2.416 2.059-4.867 4.082-7.438 5.986 2.095-2.416 4.308-4.701 6.545-6.938 2.25-2.249 4.558-4.427 6.867-6.593 2.344-2.142 4.7-4.272 7.128-6.33 1.928-1.643 3.891-3.273 5.914-4.82.464-.369.94-.714 1.416-1.071a.352.352 0 00.107-.071zM84.91 255.228c-2.975-1.143-5.89-2.44-8.77-3.773-2.88-1.332-5.725-2.748-8.557-4.176-2.82-1.464-5.628-2.94-8.39-4.511-2.248-1.261-4.486-2.57-6.663-3.962-.476-.298-.928-.595-1.393-.893l-.107-.071a.4.4 0 00.131.047c.56.227 1.107.441 1.654.667 2.368.952 4.677 1.987 6.986 3.058 2.88 1.333 5.724 2.749 8.544 4.189 2.82 1.464 5.629 2.939 8.39 4.51 2.772 1.547 5.509 3.153 8.175 4.915z"
      ></path>
      <path fill="#E0DEFA" d="M451.175 158H354v61.63h97.175V158z"></path>
      <path
        fill="#fff"
        d="M451.175 158c-3.927 3.749-7.925 7.342-11.876 11.043a1679.518 1679.518 0 01-11.995 10.805c-4.189 3.737-8.39 7.426-12.59 11.103-.131.119-.25.238-.381.357a8497.875 8497.875 0 01-11.198 9.71l-.559.453-.536-.453a2155.694 2155.694 0 01-11.174-9.734 4.831 4.831 0 01-.393-.333c-4.212-3.677-8.425-7.366-12.613-11.126a1645.872 1645.872 0 01-11.984-10.782c-3.951-3.689-7.949-7.283-11.876-11.043 4.177 3.213 8.271 6.593 12.4 9.889 4.117 3.356 8.235 6.711 12.316 10.127 4.558 3.772 9.104 7.604 13.65 11.483.011 0 .011 0 .023.024 3.404 2.88 6.795 5.783 10.199 8.675 3.391-2.903 6.783-5.819 10.186-8.675.012-.036.012-.036.024-.036 4.534-3.855 9.08-7.687 13.649-11.483a1220.74 1220.74 0 0112.305-10.103c4.153-3.308 8.246-6.688 12.423-9.901z"
      ></path>
      <path
        fill="#fff"
        d="M392.509 190.903c-.048.024-.072.072-.119.107-.512.441-1.024.881-1.535 1.298a178.408 178.408 0 01-7.474 5.938c-3.094 2.368-6.259 4.629-9.436 6.878-3.201 2.201-6.403 4.391-9.687 6.497-3.284 2.107-6.593 4.153-10.02 6.045 2.951-2.582 6.01-4.986 9.104-7.342 3.106-2.368 6.271-4.629 9.436-6.878 3.213-2.213 6.426-4.403 9.699-6.509 2.618-1.678 5.272-3.332 7.985-4.879a96.342 96.342 0 011.892-1.071c.059-.036.119-.06.155-.084zM450.937 217.643c-3.427-1.88-6.759-3.939-10.019-6.045-3.285-2.107-6.498-4.296-9.711-6.498-3.177-2.249-6.343-4.51-9.437-6.878-2.522-1.916-5.021-3.867-7.437-5.926-.524-.44-1.035-.881-1.547-1.309-.048-.024-.071-.071-.119-.107.048.024.107.059.143.071.642.369 1.273.714 1.892 1.083 2.713 1.547 5.355 3.201 7.985 4.879 3.284 2.106 6.497 4.296 9.686 6.509 3.178 2.25 6.343 4.511 9.437 6.879 3.118 2.356 6.188 4.76 9.127 7.342z"
      ></path>
      <path fill="#F9EAE9" d="M532.024 348H398v85h134.024v-85z"></path>
      <path
        fill="#fff"
        d="M532.024 348c-5.416 5.17-10.931 10.126-16.38 15.231a2347.884 2347.884 0 01-16.543 14.902 2453.182 2453.182 0 01-17.365 15.313c-.18.164-.344.328-.525.492-5.137 4.465-10.291 8.929-15.444 13.393l-.771.624-.739-.624a2949.101 2949.101 0 01-15.411-13.425c-.181-.132-.361-.296-.542-.46-5.81-5.071-11.62-10.159-17.397-15.346a2277.666 2277.666 0 01-16.527-14.869c-5.449-5.088-10.964-10.045-16.38-15.231 5.761 4.431 11.407 9.092 17.102 13.639 5.678 4.628 11.357 9.256 16.987 13.967a1622.82 1622.82 0 0118.825 15.838c.016 0 .016 0 .033.033 4.693 3.971 9.371 7.976 14.065 11.964 4.678-4.004 9.355-8.025 14.049-11.964.016-.05.016-.05.033-.05a2142.302 2142.302 0 0118.825-15.838 1715.276 1715.276 0 0116.97-13.934c5.728-4.563 11.374-9.224 17.135-13.655z"
      ></path>
      <path
        fill="#fff"
        d="M451.111 393.381c-.065.033-.098.098-.164.148-.705.607-1.411 1.214-2.117 1.789-3.364 2.839-6.811 5.547-10.307 8.189-4.267 3.266-8.633 6.385-13.015 9.487-4.415 3.036-8.83 6.056-13.36 8.961-4.529 2.905-9.092 5.728-13.819 8.337 4.07-3.561 8.288-6.876 12.556-10.126 4.283-3.266 8.649-6.384 13.015-9.486 4.431-3.053 8.862-6.073 13.376-8.978 3.61-2.314 7.271-4.596 11.013-6.729.869-.509 1.723-.985 2.609-1.477.082-.049.164-.082.213-.115zM531.695 430.259c-4.726-2.593-9.322-5.432-13.819-8.337-4.53-2.905-8.961-5.925-13.392-8.961-4.383-3.102-8.748-6.221-13.015-9.487-3.48-2.642-6.926-5.334-10.258-8.173-.722-.608-1.428-1.215-2.134-1.806-.065-.032-.098-.098-.164-.147.066.032.148.082.197.098.886.509 1.756.985 2.61 1.494 3.742 2.133 7.385 4.415 11.012 6.729 4.53 2.905 8.962 5.925 13.36 8.977 4.382 3.102 8.748 6.221 13.015 9.487 4.3 3.249 8.535 6.565 12.588 10.126z"
      ></path>
      <path
        fill="#EBEBEB"
        d="M294 579c161.267 0 292-13.655 292-30.5S455.267 518 294 518 2 531.655 2 548.5 132.733 579 294 579z"
      ></path>
      <path
        fill="#EE91AF"
        d="M101.27 540.463l198.242.857c48.183-37.842 53.157-93.891 18.79-127.865-20.801-20.552-47.219-16.411-72.376-19.671-.024 0-.048-.012-.071-.012-8.759-1.142-17.351-3.189-25.514-7.485-25.906-13.661-40.508-45.898-62.297-73.019a164.385 164.385 0 00-8.532-9.888c-17.921-19.136-53.586-19.754-72.245 7.14-47.41 68.329-25.704 170.062 24.003 229.943z"
      ></path>
      <path
        fill="#000"
        d="M96.866 384.062c5.189 81.336 99.127 111.693 157.783 87.334 51.003-21.194 34.319-69.544-8.735-77.636-.024 0-.048-.011-.071-.011-8.759-1.143-17.351-3.19-25.514-7.486-25.906-13.661-40.508-45.898-62.297-73.018-32.463-16.862-64.974 11.269-61.166 70.817z"
        opacity="0.1"
      ></path>
      <path
        stroke="#263238"
        strokeDasharray="4 4"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        d="M312.019 67.233s-19.015 51.608-65.637 73.437c-56.823 26.604-81.018 16.994-77.62-.406 3.398-17.4 35.788 7.764 33.765 32.95-1.97 24.574-33.591 22.626-63.017 13.773-29.427-8.852-41.618-41.378-77.51-25.708"
      ></path>
      <path
        fill="#DE3069"
        d="M312.847 41.376l-1.057 26.598 13.658-20.044L387.013.434l-74.166 40.942z"
      ></path>
      <path
        fill="#000"
        d="M312.847 41.376l-1.057 26.598 13.658-20.044L387.013.434l-74.166 40.942z"
        opacity="0.1"
      ></path>
      <path
        fill="#263238"
        d="M329.57 52.735l-17.78 15.239 13.658-20.044 4.122 4.805z"
      ></path>
      <path
        fill="#DE3069"
        d="M325.449 47.93L387.014.433l-39.298 73.975-22.267-26.478z"
      ></path>
      <path
        fill="#DE3069"
        d="M312.848 41.376L277.95 31.94 387.014.434l-74.166 40.942zM423.115 563.955l-4.874-5.107 13.803-12.11-.445-.486c.499-.022 10.86-1.629 15.978-2.43l-24.462 20.133zM158.358 523.532l6.035-3.346-2.613 16.286-12.484 13.354 9.062-26.294z"
      ></path>
      <path
        fill="#FBD1C6"
        d="M431.747 546.455l-4.831-5.313-13.549 12.598 4.498 4.638 13.882-11.923zM174.771 530.801l.307-16.54-10.686 5.924-2.612 16.287 12.991-5.671z"
      ></path>
      <path
        stroke="#2E2E2E"
        d="M431.574 546.282l-13.803 12.11m-255.992-21.921l2.664-16.74"
      ></path>
      <path
        fill="#5991F2"
        d="M344.718 450.783l-94.008-60.708-89.296 8.26-.526 31.985c-.242 6.636 2.464 21.762 15.229 29.171 12.766 7.409 37.346 21.137 48.041 27.075l-49.424 27.398-.464 25.014 93.662-27.688c11.297-3.968 26.92-16.42 14.969-38.413l54.021 20.296 70.234 65.724 19.416-18.052-81.854-90.062z"
      ></path>
      <path
        fill="#2E2E2E"
        d="M282.903 472.878l54.021 20.296-113.121-79.478 59.1 59.182z"
      ></path>
      <path
        stroke="#fff"
        d="M178.565 511.799l.039 13.58m.036 12.308l-.036-12.308m1.045-128.73l-.541 21.593c-.442 4.382.845 14.366 9.532 19.24 8.687 4.875 48.531 27.603 67.367 38.358 5.021 2.191 11.553 14.199-1.681 19.53-13.234 5.33-55.995 22.227-75.722 30.009m243.42 10.463l-19.853 18.39"
      ></path>
      <path
        stroke="#2E2E2E"
        d="M426.571 540.845l-19.416 18.052m-248.963-35.69l16.541-9.243m0 0l49.424-27.398 12.383-6.837m-61.807 34.235l-.464 25.014"
      ></path>
      <path
        fill="#FFCFC4"
        stroke="#252525"
        d="M280.228 210.528c3.521-1.702 10.165 9.695 12.844 14.648l.383 2.238-3.703-.221-9.524-16.665z"
      ></path>
      <path
        fill="#FFCFC4"
        d="M87.527 435.985l12.773-15.024 17.896 13.525c-3.789 3.209-14.128 9.47-25.166 8.84-7.534-.431-6.148-3.738-2.781-6.853a6.758 6.758 0 01-2.722-.488z"
      ></path>
      <path
        stroke="#252525"
        d="M61.135 397.641l5.18 14.747m5.39-33.221l2.43 32.606"
      ></path>
      <path
        fill="#302F34"
        d="M52.341 445.87l51.826 10.941 10.839-19.85-51.473-10.771-11.192 19.68z"
      ></path>
      <path
        stroke="#302F34"
        d="M63.76 426.853l-1.526-4.345m0 0l51.958 10.961.224 3.527m-52.182-14.488l-11.092 19.948 1.722 3.138"
      ></path>
      <path
        fill="#FFCFC4"
        stroke="#000"
        d="M88.606 454.427l-.294.078-.066.298c-.16.737-.188 1.87.41 2.712.648.915 1.869 1.282 3.741.784 4.505-1.198 6.4-2.975 7.01-3.841.196-.279.349-.702.416-1.136.069-.437.063-.96-.134-1.429-.208-.493-.617-.896-1.266-1.051-.62-.147-1.412-.06-2.407.289-.568.2-1.664.729-2.843 1.298l-.791.381c-1.504.721-2.987 1.406-3.776 1.617z"
      ></path>
      <path
        fill="#FFCFC4"
        stroke="#000"
        d="M83.603 453.302l-.294.078-.066.298c-.16.737-.188 1.87.41 2.712.648.915 1.869 1.282 3.741.784 4.505-1.198 6.4-2.975 7.01-3.841.196-.279.349-.702.417-1.136.068-.437.061-.96-.135-1.429-.208-.493-.617-.896-1.266-1.051-.619-.147-1.412-.06-2.407.289-.568.2-1.663.729-2.843 1.298l-.791.381c-1.504.721-2.987 1.406-3.776 1.617z"
      ></path>
      <path
        fill="#FFCFC4"
        stroke="#000"
        d="M79.74 451.053l-.294.078-.065.298c-.16.737-.188 1.87.409 2.712.649.915 1.87 1.282 3.742.784 4.505-1.199 6.4-2.975 7.01-3.841.196-.279.349-.702.416-1.136.069-.437.062-.96-.135-1.429-.207-.493-.616-.896-1.266-1.051-.618-.147-1.411-.06-2.406.289-.568.2-1.664.729-2.843 1.298l-.792.381c-1.503.721-2.986 1.406-3.775 1.617z"
      ></path>
      <path
        fill="#FFCFC4"
        d="M87.527 435.985l12.774-15.024 5.623 9.324c-2.614 4.693-8.015 3.474-10.389 2.278-1.906 4.628-6.133 4.209-8.008 3.422z"
      ></path>
      <path
        stroke="#252525"
        d="M100.301 420.961l-12.774 15.024c1.875.787 6.102 1.206 8.008-3.422 2.374 1.196 7.775 2.415 10.389-2.278"
      ></path>
      <path
        fill="#E0DEFA"
        d="M323.766 347c-9.111 6.369-22.18 2.353-34.205-3.62l-44.812-19.921 7.877 68.087-91.262 7.359c-7.784 12.233-22.09 24.281-43.34 35.739L100.3 420.961c15.975-18.626 35.379-41.233 36.804-42.825 1.841-2.056 2.841-4.682 3.111-5.738 2.325-16.25 7.399-51.311 9.094-61.548 1.696-10.237 6.259-13.564 8.329-13.948l22.741-12.25 20.089-5.969c2.525-.281 10.544-1.056 22.423-1.907 11.879-.851 17.733.156 25.256 4.189l46.04 25.007-5.394-55.874c-.084-.872-.16-1.712-.231-2.526l20.481 2.908c6.983 16.115 16.846 40.818 23.761 66.148 4.049 14.835-2.526 25.821-9.038 30.372z"
      ></path>
      <path
        fill="#2E2E2E"
        d="M289.562 343.382l-44.812-19.921-1.584-9.821 46.396 29.742zM161.295 391.598l.07 7.309c-1.911 3.003-4.215 5.995-6.918 8.97 0 0 6.382-67.503 5.296-89.864l.888.185.603 67.059.011 1.196.025 2.573.025 2.572z"
      ></path>
      <path
        fill="#FFCFC4"
        stroke="#252525"
        d="M289.272 247.518l-.001.407.399.084 18.493 3.863a.512.512 0 00.683-.292c.042-.114.034-.217.032-.245l-.005-.052-.013-.07a4.305 4.305 0 00-.158-.512 46.516 46.516 0 00-.729-1.856 425.495 425.495 0 00-2.705-6.353 3804.6 3804.6 0 00-2.084-4.799c-1.596-3.67-3.19-7.334-4.274-9.915-.471-1.749-1.944-4.752-3.392-7.127-.73-1.197-1.474-2.269-2.104-2.955-.307-.335-.629-.626-.942-.771a.977.977 0 00-.598-.09.806.806 0 00-.541.395l-.067.114-.003.133-.005.386-.001.033.004.034.756 6.343.001.014.003.015c.082.453.117.893.083 1.204a1.129 1.129 0 01-.039.2.971.971 0 01-.131-.073c-.158-.103-.371-.283-.646-.576-.548-.585-1.289-1.559-2.27-3.075-.991-1.529-1.878-2.976-2.694-4.307l-.581-.947c-.97-1.575-1.846-2.966-2.675-4.079-.824-1.105-1.634-1.983-2.486-2.488-.884-.523-1.815-.646-2.799-.236a.507.507 0 00-.309.428v.001c-.005.067.004.122.007.142a.948.948 0 00.033.125c.022.067.055.152.093.248.079.194.2.473.354.823.128.293.291.661.479 1.084.244.549.528 1.189.83 1.876a240.686 240.686 0 014.065 9.777c2.986 7.699 5.955 16.875 5.937 23.089z"
      ></path>
      <path
        fill="#252525"
        fillRule="evenodd"
        d="M197.618 302.364c8.865-3.113 14.026-11.403 11.528-18.516-2.498-7.113-11.71-10.355-20.574-7.241-8.865 3.113-14.026 11.403-11.528 18.516 2.498 7.113 11.71 10.355 20.574 7.241zm-1.26-2.838c7.543-2.649 12.125-9.161 10.234-14.545s-9.539-7.6-17.082-4.951c-7.543 2.649-12.125 9.161-10.234 14.545s9.539 7.6 17.082 4.951z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FFCFC4"
        d="M179.579 295.438l-10.09-45.522 15.575-2.459 18.932 31.75c11.643 18.5-16.599 27.415-24.417 16.231z"
      ></path>
      <path
        fill="#252525"
        d="M169.488 249.916l15.576-2.459 5.645 9.468-14.459 23.497-6.762-30.506z"
      ></path>
      <path
        fill="#2B3036"
        d="M169.023 240.739l-97.801 31.855c17.629 3.811 34.808 14.205 60.09 6.767 21.985-7.156 33.971-25.639 37.711-38.622z"
      ></path>
      <ellipse
        cx="178.396"
        cy="245.066"
        fill="#FFCFC4"
        rx="11.486"
        ry="15.851"
        transform="rotate(-64.288 178.396 245.066)"
      ></ellipse>
      <path
        fill="#2B3036"
        d="M180.24 233.493c-6.917-2.091-13.734-.265-16.123 4.697-2.253 4.679.127 10.695 5.391 14.807l10.732-19.504z"
      ></path>
      <path stroke="#252525" d="M180.238 233.492l-10.731 19.504"></path>
      <circle cx="112" cy="125" r="12" fill="#FFDA2A"></circle>
    </svg>
  )
}

export default ContactSVG
