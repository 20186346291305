import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import AppStoreLightLargeSVG from '../icons/appStoreLightLargeSVG'

const Cta = ({ appStoreLink }) => {
  return (
    <section id="cta">
      <div className="flex flex-col items-center layout-space md:flex-row-reverse">
        <div className="w-full mb-6 md:w-3/5 md:mb-0 md:py-8">
          <div className="max-w-2xl pt-12 text-center md:pt-0 md:pl-8">
            <h2 className="mb-4 text-white heading">
              Download our voice to text app and transcribe 30 minutes free!
            </h2>
            <p className="mb-8 text-white sub-heading">
              Get a complimentary 30 minutes of speech-to-text service when you
              register.
            </p>
            <a href={appStoreLink} target="_blank" rel="noreferrer">
              <AppStoreLightLargeSVG className="mx-auto app-store-link" />
            </a>
          </div>
        </div>
        <div
          // className="relative flex-shrink-0"
          // style={{ width: '320px', height: '400px' }}
          className="w-full mr-8 md:w-2/5 md:pt-8 "
        >
          <StaticImage
            src="../images/devices-group.png"
            className="w-full"
            alt="VoxRec on IOS"
          />
        </div>
      </div>
    </section>
  )
}

export default Cta
