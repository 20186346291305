import React from 'react'
import BookMarkSVG from '../icons/bookMarkSVG'
import ClockSVG from '../icons/clockSVG'
import SecuritySVG from '../icons/securitySVG'

const Features = () => {
  return (
    <section id="features">
      <div className="pt-16 pb-8 md:pt-12 layout-space lg:pt-20 xl:pt-32 xl:pb-32">
        <div className=" md:text-center md:mb-16">
          <h2 className="mb-3 heading">More than a transcription app</h2>
          <p className="mb-8 sub-heading">
            Productivity features will help you work with your recordings and
            transcriptions to achieve even more than ever before in the same
            time.
          </p>
        </div>
        <div className="max-w-6xl mx-auto md:flex lg:px-10">
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1 blue-dot-after">
            <div className="pt-6 pb-4">
              <ClockSVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">Transcript via Email</h3>
            <h4 className="my-1 sub-heading text-accent bold">
              Within Minutes
            </h4>
            <p className="pb-6 small">
              Your voice memos are transcribed and automatically delivered to
              your email in a jiffy
            </p>
          </div>
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1">
            <div className="pt-6 pb-4">
              <SecuritySVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">Always Secure</h3>
            <h4 className="my-1 sub-heading text-accent bold">Always Synced</h4>
            <p className="pb-6 small">
              All meetings, notes, voice memos and transcripts backed up to your
              cloud (iCloud, Dropbox, Google Drive)
            </p>
          </div>
          <div className="max-w-sm px-4 mx-auto mb-6 text-center bg-white shadow-xl xl:px-8 rounded-xl md:mx-2 md:flex-1">
            <div className="pt-6 pb-4">
              <BookMarkSVG className="w-12 h-12 mx-auto" />
            </div>
            <h3 className="uppercase bold sub-heading">Industry Leading</h3>
            <h4 className="my-1 sub-heading text-accent bold">
              A.I. Transcription
            </h4>
            <p className="pb-6 small">
              Transcription by human takes hours. Get more out of your
              interactions and meetings by letting VoxRec transcribe your
              discussions.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Features
