import React from 'react'

function CloseIconSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="#B9BFD2"
        d="M4.34 5.981A1.16 1.16 0 015.98 4.34L19.66 18.019a1.16 1.16 0 01-1.642 1.641L4.34 5.981z"
      ></path>
      <path
        fill="#B9BFD2"
        d="M18.019 4.34A1.16 1.16 0 1119.66 5.98L5.983 19.66a1.16 1.16 0 11-1.642-1.641L18.02 4.34z"
      ></path>
    </svg>
  )
}

export default CloseIconSVG
