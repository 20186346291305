import React from 'react'

function BookMarkSVG({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="38"
      // height="44"
      className={className}
      fill="none"
      viewBox="0 0 38 44"
    >
      <path
        stroke="#252525"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.541 30.937a1 1 0 00-1.082 0L2.541 41.177A1 1 0 011 40.334V6a5 5 0 015-5h26a5 5 0 015 5v34.335a1 1 0 01-1.541.841l-15.918-10.24z"
      ></path>
      <path
        fill="#DE3069"
        d="M14.675 24.845c-.877.482-1.796-.225-1.63-1.237l.826-5.034-3.5-3.564c-.708-.723-.35-1.855.623-2.003l4.836-.734 2.163-4.58c.438-.928 1.58-.92 2.015 0l2.162 4.58 4.836.734c.98.15 1.327 1.286.623 2.003l-3.5 3.564.826 5.034c.168 1.02-.76 1.715-1.63 1.237L19 22.47l-4.325 2.376zm3.555-4.165a1.663 1.663 0 011.54 0l3.082 1.693-.589-3.586c-.087-.533.104-1.152.476-1.531l2.493-2.54-3.445-.522c-.512-.078-1.016-.46-1.246-.947L19 9.985l-1.541 3.262c-.23.485-.733.869-1.247.947l-3.445.523 2.493 2.539c.37.377.564.996.476 1.531l-.588 3.586 3.081-1.693z"
      ></path>
    </svg>
  )
}

export default BookMarkSVG
