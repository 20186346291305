import React from 'react'

function ClockSVG({ className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="42"
      // height="46"
      className={className}
      fill="none"
      viewBox="0 0 42 46"
    >
      <path
        fill="#252525"
        stroke="#fff"
        strokeWidth="0.5"
        d="M15.381 1.002a1.363 1.363 0 00-1.144.75c-.22.438-.194.962.071 1.374a1.36 1.36 0 001.215.626H18.7v2.807C8.824 7.267 1 15.599 1 25.75 1 36.366 9.55 45 20.062 45a1.37 1.37 0 001.302-1.375 1.37 1.37 0 00-1.302-1.375c-9.04 0-16.339-7.37-16.339-16.5 0-9.128 7.299-16.498 16.339-16.498S36.4 16.622 36.4 25.75c0 7.674-5.165 14.098-12.169 15.955-.47.123-.841.49-.972.963-.13.474 0 .982.34 1.333.341.35.842.49 1.313.368 8.18-2.17 14.211-9.696 14.211-18.62 0-4.818-1.756-9.224-4.666-12.603l1.943-1.962 2.213 2.234c.333.386.852.553 1.345.433.493-.12.879-.508 1.002-1.005a1.383 1.383 0 00-.419-1.361l-6.353-6.417a1.354 1.354 0 00-1.12-.415 1.364 1.364 0 00-1.142.916 1.385 1.385 0 00.333 1.433l2.212 2.249-1.943 1.962c-3.024-2.646-6.874-4.352-11.105-4.655V3.752H24.6c.491.007.948-.254 1.195-.682a1.386 1.386 0 000-1.386 1.359 1.359 0 00-1.195-.682h-9.077a1.346 1.346 0 00-.142 0z"
      ></path>
      <path
        fill="#DE3069"
        stroke="#fff"
        strokeWidth="0.5"
        d="M20.047 12.888c-.364.004-.71.154-.964.418a1.382 1.382 0 00-.383.985v7.577a4.144 4.144 0 00-2.723 3.881c0 2.262 1.844 4.125 4.084 4.125 2.24 0 4.085-1.863 4.085-4.125 0-1.78-1.145-3.309-2.723-3.881V14.29a1.382 1.382 0 00-.394-.995 1.355 1.355 0 00-.982-.408z"
      ></path>
      <path
        fill="#fff"
        d="M20.061 24.376c.768 0 1.362.6 1.362 1.375 0 .776-.594 1.375-1.362 1.375-.768 0-1.361-.6-1.361-1.375 0-.776.593-1.375 1.361-1.375z"
      ></path>
    </svg>
  )
}

export default ClockSVG
