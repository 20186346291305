import React from 'react'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'

const Pricing = ({ appStoreLink }) => {
  return (
    <section id="pricing">
      <div className="pt-4 md:pt-12 layout-space">
        <div className="max-w-6xl pb-12 mx-auto lg:px-10 md:pb-20 xl:pb-24">
          <div className="mb-12 md:text-center md:mb-16">
            <h2 className="mb-4 heading">Pricing</h2>
            <p className="md:max-w-xl sub-heading md:mx-auto">
              VoxRec app is FREE to download. Our recording features are
              completely FREE. We offer monthly subscriptions or pay as you go
              options for the speech-to-text service.
            </p>
          </div>

          <div className="md:flex md:flex-row-reverse md:items-center">
            <div className="text-center md:flex md:flex-wrap md:text-left xl:w-3/5 md:w-1/2 md:items-start">
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Playback highlight</h5>
                <p className="small">
                  Follow the speaker and the discussion with visual word
                  highlight during playback. Tap at any word to start playback.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Format &amp; Edit</h5>
                <p className="small">
                  Add notes to recordings, edit transcripts, format or highlight
                  text directly on your iPhone or iPad.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Tag important time</h5>
                <p className="small">
                  When discussion turns serious, mark important moments in the
                  recording. Return to them with one tap.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Backup &amp; Sync</h5>
                <p className="small">
                  Dropbox, Google Drive or iCloud. Your recordings and
                  transcripts will be securely archived.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-2/5 card-background-image">
              <div className="z-10 max-w-sm px-6 py-8 mx-auto bg-white shadow-lg md:mx-0 md:px-10 md:py-12 rounded-xl bg-10">
                <div className="mb-4">
                  <p className="from">from</p>
                </div>
                <div className="flex items-end mb-4">
                  <h3 className="big-money">$0.07</h3>
                  <p className="mb-1 text-black md:text-3xl">
                    &nbsp;/&nbsp;minute
                  </p>
                </div>
                <hr className="mb-2" />
                <h4 className="mb-6 text-accent">
                  30 minutes FREE{' '}
                  <span className="lg:hidden md:hidden text-accent">
                    of speech to text
                  </span>
                </h4>
                <div className="md:justify-between md:flex md:items-center">
                  <a href={appStoreLink} target="_blank" rel="noreferrer">
                    <AppStoreDarkLargeSVG className="mb-4 app-store-link md:mb-0 md:mr-8" />
                  </a>
                  <p className="small">
                    Available for iPhone, <br />
                    iPad, &amp; Apple Watch
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing
